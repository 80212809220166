import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent} from './theme/layout/auth/auth.component';
import { SessionGuardService as AuthGuard} from './service/session-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'insurance-register', loadChildren: () => import('../app/pages/insurance-register/insurance-register.module').then(module => module.InsuranceRegisterModule)
  },
  {
    path: 'insurance-register/:id', loadChildren: () => import('../app/pages/insurance-register/insurance-register.module').then(module => module.InsuranceRegisterModule)
  },
  {
    path: 'insurance-main', loadChildren: () => import('../app/pages/insurance-main/insurance-main.module').then(module => module.InsuranceMainModule)
  },
  {
    path: 'insurance-main/:id', loadChildren: () => import('../app/pages/insurance-main/insurance-main.module').then(module => module.InsuranceMainModule)
  },
  {
    path: 'insurance-check', loadChildren: () => import('../app/pages/insurance-check/insurance-check.module').then(module => module.InsuranceCheckModule)
  },
  {
    path: 'insurance-present', loadChildren: () => import('../app/pages/insurance-present/insurance-present.module').then(module => module.InsurancePresentModule)
  },
  {
    path: 'insurance-qna', loadChildren: () => import('../app/pages/insurance-qna/insurance-qna.module').then(module => module.InsuranceQNAModule)
  },
  {
    path: 'insurance-history', loadChildren: () => import('../app/pages/insurance-history/insurance-history.module').then(module => module.InsuranceHistoryModule)
  },
  {
    path: 'insurance-schedule', loadChildren: () => import('../app/pages/insurance-schedule/insurance-schedule.module').then(module => module.InsuranceScheduleModule)
  },
  {
    path: 'insurance-newwarranty', loadChildren: () => import('../app/pages/admin/insurance-newwarranty/insurance-newwarranty.module').then(module => module.InsuranceNewWarrantyModule)
  },
  {
    path: 'insurance-newactualwarranty', loadChildren: () => import('../app/pages/admin/insurance-newactualwarranty/insurance-newactualwarranty.module').then(module => module.InsuranceNewActualWarrantyModule)
  },
  {
    path: 'insurance-warranty', loadChildren: () => import('../app/pages/admin/insurance-warranty/insurance-warranty.module').then(module => module.InsuranceWarrantyModule)
  },
  {
    path: 'insurance-expense', loadChildren: () => import('../app/pages/admin/insurance-expense/insurance-expense.module').then(module => module.InsuranceExpenseModule)
  },
  {
    path: 'insurance-newexpense', loadChildren: () => import('../app/pages/admin/insurance-newexpense/insurance-newexpense.module').then(module => module.InsuranceNewExpenseModule)
  },
  {
    path: 'insurance-newactualexpense', loadChildren: () => import('../app/pages/admin/insurance-newactualexpense/insurance-newactualexpense.module').then(module => module.InsuranceNewActualExpenseModule)
  },
  {
    path: 'insurance-login', loadChildren: () => import('../app/pages/admin/insurance-login/insurance-login.module').then(module => module.InsuranceLoginModule)
  },
  {
    path: 'insurance-request', loadChildren: () => import('../app/pages/insurance-request/insurance-request.module').then(module => module.InsuranceRequestModule)
  },
  {
    path: 'insurance-manyrequest', loadChildren: () => import('../app/pages/insurance-manyrequest/insurance-manyrequest.module').then(module => module.InsuranceManyrequestModule)
  },
  {
    path: 'insurance-payment', loadChildren: () => import('../app/pages/insurance-payment/insurance-payment.module').then(module => module.InsurancePaymentModule)
  },
  {
    path: 'insurance-registeradmin', loadChildren: () => import('../app/pages/admin/insurance-registeradmin/insurance-registeradmin.module').then(module => module.InsuranceRegisteradminModule)
  },
  {
    path: 'insurance-country', loadChildren: () => import('../app/pages/admin/insurance-country/insurance-country.module').then(module => module.InsuranceCountryModule)
  },
  {
    path: 'insurance-couponadmin', loadChildren: () => import('../app/pages/admin/insurance-couponadmin/insurance-couponadmin.module').then(module => module.InsuranceCouponadminModule)
  },
  {
    path: 'insurance-qnaadmin', loadChildren: () => import('../app/pages/admin/insurance-qnaadmin/insurance-qnaadmin.module').then(module => module.InsuranceQnaadminModule)
  },
  {
    path: 'insurance-licenseadmin', loadChildren: () => import('../app/pages/admin/insurance-licenseadmin/insurance-licenseadmin.module').then(module => module.InsuranceLicenseadminModule)
  },
  {
    path: 'traveler-immigration-make', loadChildren: () => import('../app/pages/admin/traveler-immigration-make/traveler-immigration-make.module').then(module => module.TravelerImmigrationMakeModule)
  },
  {
    path: 'insurance-confirmation', loadChildren: () => import('../app/pages/insurance-confirmation/insurance-confirmation.module').then(module => module.InsuranceConfirmationkModule)
  },
  {
    path: 'insurance-english-req',  loadChildren: () => import('../app/pages/insurance-english-req/insurance-english-req.module').then( m => m.InsuranceEnglishReqPageModule)
  },
  {
    path: 'insurance-korea-req', loadChildren: () => import('../app/pages/insurance-korea-req/insurance-korea-req.module').then( m => m.InsuranceKoreaReqPageModule)
  },
  {
    path: 'insurance-return', loadChildren: () => import('../app/pages/insurance-return/insurance-return.module').then( m => m.InsuranceReturnModule)
  },
  {
    path: 'insurance-site', loadChildren: () => import('../app/pages/admin/insurance-site/insurance-site.module').then( m => m.InsuranceSiteModule)
  },
  {
    path: 'insurance-immigration', loadChildren: () => import('../app/pages/insurance-immigration/insurance-immigration.module').then( m => m.InsuranceImmigrationModule)
  },
  {
    path: 'insurance-coupone', loadChildren: () => import('../app/pages/insurance-coupone/insurance-coupone.module').then( m => m.InsuranceCouponeModule)
  },
  {
    path: 'insurance-immigration-view', loadChildren: () => import('../app/pages/insurance-immigration-view/insurance-immigration-view.module').then( m => m.InsuranceImmigrationViewModule)
  },
  {
    path: 'insurance-coupone-view', loadChildren: () => import('../app/pages/insurance-coupone-view/insurance-coupone-view.module').then( m => m.InsuranceCouponeViewModule)
  }
 ];



@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }




